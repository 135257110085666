'use strict';

/* contentpage.js -- Components for template specific items */


// Register event handlers here (like jQuery load)
function _registerEventHandlers() {

    //jquery ready event
    $(document).ready(function () {


    });

}

_registerEventHandlers();